import React, { useState, useEffect } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { getData, getMe } from "../../../../utils/api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../pages/auth/store/auth.slice";
import { fetchCurrentUser } from "../../../../pages/auth/store/auth.action";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { findUpper } from "../../../../utils/Utils";

const User = () => {
  const [open, setOpen] = useState(false);
  // const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  // console.log(user, "user");
  const toggle = () => setOpen((prevState) => !prevState);

  useEffect(() => {
    dispatch(fetchCurrentUser("df"));
  }, [dispatch]);
  const handleSignout = () => {
    localStorage.removeItem("accessToken");
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" />
          <div className="user-info d-none d-md-block">
            <div className="user-status">
              {user?.roleId == 1 ? "Admin" : user?.roleId == 2 ? "System User" : "Default"}
            </div>
            <div className="user-name dropdown-indicator">
              {user?.firstName} {user?.lastName}
            </div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>{findUpper(user?.firstName)}</span>
            </div>
            <div className="user-info">
              <Link to={`${process.env.PUBLIC_URL}/me`}>
                <span className="lead-text">
                  {user?.firstName} {user?.lastName}
                </span>
                <span className="sub-text">{user?.email}</span>
              </Link>
            </div>
          </div>
        </div>

        <div className="dropdown-inner">
          <LinkList>
            <a href={`${process.env.PUBLIC_URL}/auth-login`} onClick={handleSignout}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
