import React from "react";
import { PropagateLoader } from "react-spinners";

const ProgLoader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <PropagateLoader className="text-center" color="#3693d6" />
    </div>
  );
};

export default ProgLoader;
