import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import _ from "lodash";
import { Modal, ModalBody, Form } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
} from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { selectWoredaList, selectZoneList } from "../../auth/store/auth.slice";

import { fetchWoredaList, fetchZoneList, saveWoreda } from "../../auth/store/auth.action";
import { PropagateLoader } from "react-spinners";
import ActionOptions from "./ActionOptions";
import { postData, updateData } from "../../../utils/api";

const WoredaList = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const { zones } = useSelector(selectZoneList);
  const { woredas, isLoading, isError } = useSelector(selectWoredaList);

  const [sm, updateSm] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    zoneId: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  // console.log(woredas);
  // Sorting data

  // console.log(selectedData, "woreda");

  // re-fetching
  const reloadData = () => {
    setTimeout(() => {
      dispatch(fetchWoredaList({ currentPage, all: 0 }));
    }, 0);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      zone: "",
    });
    setErrorArray([]);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = async () => {
    console.log(formData, "woreda to be summitted");
    // dispatch(saveWoreda(formData));
    setLoading(true);
    const result = await postData(`/woreda`, formData);
    // const result = await dispatch(saveZone(formData));
    // console.log(result, "dispatch response");
    if (result?.data?.status == false) {
      setErrorArray(result?.data?.errors);
      setLoading(false);
      return;
    }
    resetForm();
    setLoading(false);
    reloadData();
    setModal({ edit: false }, { add: false });
  };

  const onFormEditSubmit = (submittedData) => {
    console.log(submittedData.zoneId, "before");

    if (!submittedData?.zoneId) {
      submittedData.zoneId = selectedData.zoneId;
    }
    setLoading(true);
    updateData(`/woreda/${selectedData.id}`, submittedData)
      .then((data) => {
        console.log(data, "response from update");
        reloadData();
        setLoading(false);
        setModal({ edit: false }, { add: false });
        // console.log(submittedData);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, setValue, register, handleSubmit } = useForm();

  useEffect(() => {
    dispatch(fetchZoneList({ all: 1 }));
    dispatch(fetchWoredaList({ currentPage, all: 0 }));
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Woreda Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {woredas?.totalItems} Woreda.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {/* Loading Component */}
          <>
            {isLoading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="p-2"></span>
                <span>
                  <PropagateLoader className="text-center" color="#3693d6" />
                </span>
              </div>
            )}
            {isError && <span className="text-danger">Something went wrong, try again</span>}
          </>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">S.N.</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Name</span>
                </DataTableRow>

                <DataTableRow size="md">
                  <span className="sub-text">Zone</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text"> Actions</span>
                </DataTableRow>
              </DataTableHead>

              {/*rendering component*/}
              {woredas?.woredaList?.length > 0
                ? woredas?.woredaList?.map((item, index) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{index + 1}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-card">
                            <UserAvatar
                              theme={item.avatarBg}
                              text={findUpper(item.name)}
                              image={item.image}
                            ></UserAvatar>
                            <div className="user-info">
                              <span className="tb-lead">
                                {item.name}{" "}
                                <span
                                  className={`dot dot-${
                                    item.status === "Active"
                                      ? "success"
                                      : item.status === "Pending"
                                      ? "warning"
                                      : "danger"
                                  } d-md-none ms-1`}
                                ></span>
                              </span>
                            </div>
                          </div>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{item.zone?.name}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <ActionOptions
                            setModal={setModal}
                            url={"woreda"}
                            item={item}
                            reloadData={reloadData}
                            setSelectedData={setSelectedData}
                            setLoading={setLoading}
                          />
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {woredas?.totalItems > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={woredas?.totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                !isLoading && (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
        </Block>
        {/* add woreda */}
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add Woreda</h5>
              <div className="d-flex flex-column justify-content-end align-items-end">
                {errorArray.length > 0 && (
                  <div className="alert alert-danger">
                    <ul>
                      {errorArray.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Zone/Town</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={zones?.zones?.map((z) => ({ value: z.id, label: z.name }))}
                          ref={register({ name: "zoneId" })}
                          name="zone"
                          onChange={(e) => setFormData({ ...formData, zoneId: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={loading ? true : false} color="primary" size="md" type="submit">
                          {loading ? "Adding ..." : " Add Woreda"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* edit woreda */}
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Edit Woreda</h5>
              <div className="d-flex flex-column justify-content-end align-items-end">
                {errorArray.length > 0 && (
                  <div className="alert alert-danger">
                    <ul>
                      {errorArray.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit(onFormEditSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        // onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        defaultValue={selectedData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Region</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={zones?.zones?.map((z) => ({ value: z.id, label: z.name }))}
                          name="zoneId"
                          {...register({ required: "this field is required" })}
                          // ref={register({ required: "this field is required" })}
                          defaultValue={{ value: selectedData?.zoneId, label: selectedData?.zone?.name }}
                          // onChange={(e) => setFormData({ ...formData, zoneId: e.value })}
                          onChange={(value) => {
                            //   console.log(value);
                            setValue("zoneId", value?.value);
                          }}
                        />
                        {/* <RSelect
                          options={filterStatus}
                          ref={register("regionId")}
                          name="regionId"
                          required={true}
                          defaultValue={{ value: 1, label: "Oromia" }}
                          // onChange={(e) => setFormData({ ...formData, regionId: e.value })}
                        /> */}
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={loading ? true : false} color="primary" size="md" type="submit">
                          {loading ? "Editing ..." : " Edit Woreda"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default WoredaList;
