import TeamImg from "../../../images/avatar/b-sm.jpg";
import TeamImg2 from "../../../images/avatar/c-sm.jpg";
import TeamImg3 from "../../../images/avatar/a-sm.jpg";
import TeamImg4 from "../../../images/avatar/d-sm.jpg";

import { setDeadline } from "../../../utils/Utils";

export const projectData = [
  {
    id: 1,
    avatarClass: "purple",
    title: "Dashlite Development",
    subtitle: "Softnio",
    desc: "Design and develop the DashLite template for Envato Marketplace",
    lead: "Abu Bin",
    tasks: "3",
    totalTask: "93",
    checked: false,
    deadline: setDeadline(20), // Format ** mm/dd/yyyy
    team: [
      {
        value: "Abu Bin",
        label: "Abu Bin",
        image: null,
        theme: "purple",
      },
      { value: "Milagros Betts", label: "Milagros Betts", theme: "pink" },
      { value: "Ryu Duke", label: "Ryu Duke", theme: "orange" },
    ],
  },
  {
    id: 2,
    avatarClass: "warning",
    title: "Redesign Website",
    subtitle: "Runnergy",
    desc: "Design the website for Runnergy main website including their user dashboard.",
    tasks: "25",
    totalTask: "230",
    lead: "Newman John",
    checked: false,
    deadline: setDeadline(5), // Format ** mm/dd/yyyy
    team: [
      {
        value: "Abu Bin",
        label: "Abu Bin",
        image: TeamImg2,
        theme: "purple",
      },
      {
        value: "Newman John",
        label: "Newman John",
        image: null,
        theme: "primary",
      },
    ],
  },
  {
    id: 3,
    avatarClass: "info",
    title: "Keyword Research for SEO",
    subtitle: "Techyspec",
    desc: "Improve SEO keyword research, A/B testing, Local market improvement",
    tasks: "2",
    totalTask: "15",
    lead: "Abu Bin",
    checked: false,
    deadline: setDeadline(1), // Format ** mm/dd/yyyy
    team: [
      {
        value: "Abu Bin",
        label: "Abu Bin",
        image: TeamImg3,
        theme: "purple",
      },
    ],
  },
  {
    id: 4,
    avatarClass: "danger",
    title: "Website Development",
    subtitle: "Fitness Next",
    desc: "Develop the website using WordPree for the Fitness Next client.",
    tasks: "44",
    totalTask: "65",
    lead: "Newman John",
    checked: false,
    deadline: setDeadline(5), // Format ** mm/dd/yyyy
    team: [
      {
        value: "Newman John",
        label: "Newman John",
        theme: "purple",
      },
      {
        value: "Abu Bin",
        label: "Abu Bin",
        image: TeamImg2,
        theme: "purple",
      },
    ],
  },
  {
    id: 5,
    avatarClass: "info",
    title: "Website Keyword Research for SEO",
    subtitle: "Techyspec",
    desc: "Improve SEO keyword research, A/B testing, Local market improvement.",
    tasks: "8",
    totalTask: "100",
    lead: "Joshua Wilson",
    checked: false,
    deadline: setDeadline(11), // Format ** mm/dd/yyyy
    team: [
      { value: "Joshua Wilson", label: "Joshua Wilson", theme: "pink" },
      {
        value: "Abu Bin",
        label: "Abu Bin",
        image: TeamImg4,
        theme: "purple",
      },
    ],
  },
  {
    id: 6,
    avatarClass: "purple",
    title: "Dashlite Development",
    subtitle: "Softnio",
    desc: "Design and develop the DashLite template for Envato Marketplace",
    tasks: "3",
    totalTask: "25",
    lead: "Milagros Betts",
    checked: false,
    deadline: setDeadline(15), // Format ** mm/dd/yyyy
    team: [
      { value: "Joshua Wilson", label: "Joshua Wilson", theme: "pink" },
      { value: "Milagros Betts", label: "Milagros Betts", theme: "purple" },
      { value: "Ryu Duke", label: "Ryu Duke", theme: "orange" },
      {
        value: "Abu Bin",
        label: "Abu Bin",
        theme: "purple",
      },
      { value: "Aliah Pitts", label: "Aliah Pitts", theme: "blue" },
    ],
  },
  
];

export const teamList = [
  { value: "Abu Bin", label: "Abu Bin", theme: "purple" },
  { value: "Newman John", label: "Newman John", theme: "primary" },
  { value: "Milagros Betts", label: "Milagros Betts", theme: "purple" },
  { value: "Joshua Wilson", label: "Joshua Wilson", theme: "pink" },
  { value: "Ryu Duke", label: "Ryu Duke", theme: "orange" },
  { value: "Aliah Pitts", label: "Aliah Pitts", theme: "blue" },
];
export const monthList = [
  { value: "January", label: "January", theme: "purple" },
  { value: "February", label: "February", theme: "" },
  { value: "March", label: "March", theme: "" },
  { value: "April", label: "April", theme: "" },
  { value: "May", label: "May", theme: "" },
  { value: "June", label: "June", theme: "" },
  { value: "July", label: "July", theme: "" },
  { value: "August", label: "August", theme: "" },
  { value: "September", label: "September", theme: "" },
  { value: "October", label: "October", theme: "" },
  { value: "November", label: "November", theme: "" },
  { value: "December", label: "December", theme: "" }  
 
];
export const ageCategory = [
  { value: "15-20", label: "15-20", theme: "purple" },
  { value: "21-25", label: "21-25", theme: "purple" },
];
