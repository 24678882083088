import axios from "axios";
import { refresh } from "./Utils";
const token = localStorage.getItem("accessToken");
const CONFIG = {
  headers: {
    Authorization: "Bearer " + token,
  },
};
// const FiLE_CONFIG = {
//   headers: {
//     Authorization: "Bearer " + token,
//     responseType: "blob",
//   },
// };
// export const BASE_ROUTE = "https://api.ohbsbcdashboard.org";
export const BASE_ROUTE = "https://sbcapi.orhb.gov.et";
// export const BASE_ROUTE = "http://localhost:4000";
// export const BASE_ROUTE =process.env.REACT_APP_BASE_ROUTE
export async function getData(route) {
  try {
    const response = await axios.get(BASE_ROUTE + route, CONFIG);
    console.log(response);
    return response;
  } catch (error) {
    console.error(error);
    if (error?.response?.status === 401) {
      removeToken();
    }
  }
}

// update data
export async function updateData(route, data) {
  console.log(data);
  try {
    const response = await axios.patch(BASE_ROUTE + route, data, CONFIG);
    return response;
  } catch (error) {
    console.error(error);
    if (error?.response?.status === 401) {
      removeToken();
    }
  }
}
// delete data
export async function deleteData(route) {
  try {
    const response = await axios.delete(BASE_ROUTE + route, CONFIG).data;

    return response;
  } catch (error) {
    console.error(error);
    if (error?.response?.status === 401) {
      removeToken();
    }
  }
}

export async function getFile(route) {
  // try {
  //   const response = await axios.get(BASE_ROUTE + route, {}, FiLE_CONFIG);
  //   return response;
  // } catch (error) {
  //   console.error(error);
  // }
}
export async function getMe() {
  try {
    const response = await (await getData("/me")).data?.user;

    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function postData(route, data) {
  try {
    const response = await axios.post(BASE_ROUTE + route, data, CONFIG);

    return response.data;
  } catch (error) {
    console.error(error);
    if (error?.response?.status === 401) {
      removeToken();
    }
    return error.response;
  }
}
export const removeToken = () => {
  localStorage.removeItem("tokens");
  localStorage.removeItem("accessToken");
  refresh("/auth-login");
};
