import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal, ModalBody, DropdownItem, Form } from "reactstrap";
import _ from "lodash";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  RSelect,
  OrderTable,
} from "../../../components/Component";
import { filterRole, filterStatus } from "./UserData";
import { bulkActionOptions, findUpper } from "../../../utils/Utils";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { selectZoneList } from "../../auth/store/auth.slice";

import { fetchZoneList, saveZone } from "../../auth/store/auth.action";
import { PropagateLoader } from "react-spinners";
import { postData, updateData } from "../../../utils/api";
import ActionOptions from "./ActionOptions";

const ZoneList = () => {
  const dispatch = useDispatch();
  const { zones, isLoading, isError } = useSelector(selectZoneList);

  const [sm, updateSm] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });

  const [formData, setFormData] = useState({
    name: "",
    regionId: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const { errors, register, setValue, handleSubmit } = useForm();

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      regionId: "",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  const reloadData = () => {
    setTimeout(() => {
      dispatch(fetchZoneList({ currentPage, all: false }));
    }, 0);
  };

  // submit function to add a new item
  const onFormSubmit = async (submitData) => {
    setLoading(true);
    const result = await postData(`/zones`, formData);
    // const result = await dispatch(saveZone(formData));
    // console.log(result, "dispatch response");
    if (result?.data?.status == false) {
      setErrorArray(result?.data?.errors);
      setLoading(false);
      return;
    }
    resetForm();
    setLoading(false);
    reloadData();
    setModal({ edit: false }, { add: false });
  };

  const onFormEditSubmit = async (submittedData) => {
    if (!submittedData.regionId) {
      submittedData.regionId = selectedData.regionId;
    }
    setLoading(true);
    const result = await updateData(`/zones/${selectedData.id}`, submittedData);

    if (result?.data?.status == false) {
      setErrorArray(result?.data?.errors);
      setLoading(false);
      return;
    }
    resetForm();
    setLoading(false);
    reloadData();
    setModal({ edit: false }, { add: false });
    console.log(submittedData);
  };

  // Get current list, pagination
  // const indexOfLastItem = currentPage * itemPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemPerPage;
  // const currentItems = zones?.zones;
  // console.log(currentPage);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  console.log(selectedData, "selected");

  useEffect(() => {
    dispatch(fetchZoneList({ currentPage, all: false }));
  }, [dispatch, currentPage]);
  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Zones and Towns Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {zones?.totalItems} Zones andTowns.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {/* Loading Component */}
          <>
            {isLoading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="p-2"></span>
                <span>
                  <PropagateLoader className="text-center" color="#3693d6" />
                </span>
              </div>
            )}
            {isError && <span className="text-danger">Something went wrong, try again</span>}
          </>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">S.N.</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Name</span>
                </DataTableRow>

                <DataTableRow size="md">
                  <span className="sub-text">Region</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Registered at</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text"> Actions</span>
                </DataTableRow>
              </DataTableHead>

              {/* rendering component */}
              {zones?.zones?.length > 0
                ? zones?.zones?.map((item, index) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{index + 1}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-card">
                            <UserAvatar
                              theme={item.avatarBg}
                              text={findUpper(item.name)}
                              image={item.image}
                            ></UserAvatar>
                            <div className="user-info">
                              <span className="tb-lead">
                                {item.name}{" "}
                                <span
                                  className={`dot dot-${
                                    item.status === "Active"
                                      ? "success"
                                      : item.status === "Pending"
                                      ? "warning"
                                      : "danger"
                                  } d-md-none ms-1`}
                                ></span>
                              </span>
                            </div>
                          </div>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <span className="tb-amount">
                            {item.region?.name} <span className="currency"></span>
                          </span>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <span className="tb-amount">
                            {item.createdAt} <span className="currency"></span>
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <ActionOptions
                            setModal={setModal}
                            item={item}
                            reloadData={reloadData}
                            setSelectedData={setSelectedData}
                            setLoading={setLoading}
                            url={"zones"}
                          />
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {zones?.totalItems > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={zones?.totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                !isLoading && (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
        </Block>
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add Zone</h5>
              <div className="d-flex flex-column justify-content-end align-items-end">
                {errorArray.length > 0 && (
                  <div className="alert alert-danger">
                    <ul>
                      {errorArray.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Region</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          ref={register("regionId")}
                          name="regionId"
                          required={true}
                          // defaultValue={{ value: "1", label: "Oromia" }}
                          onChange={(e) => setFormData({ ...formData, regionId: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={loading ? true : false} color="primary" size="md" type="submit">
                          {loading ? "Adding ..." : "Add Zone/Town"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Edit Zone</h5>
              <div className="d-flex flex-column justify-content-end align-items-end">
                {errorArray.length > 0 && (
                  <div className="alert alert-danger">
                    <ul>
                      {errorArray.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit(onFormEditSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        // onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        defaultValue={selectedData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Region</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          name="regionId"
                          {...register({ required: "this field is required" })}
                          // ref={register({ required: "this field is required" })}
                          defaultValue={{ value: selectedData.regionId, label: selectedData?.region?.name }}
                          onChange={(value) => {
                            setValue("regionId", value?.value);
                          }}
                        />
                        {/* <RSelect
                          options={filterStatus}
                          ref={register("regionId")}
                          name="regionId"
                          required={true}
                          defaultValue={{ value: 1, label: "Oromia" }}
                          // onChange={(e) => setFormData({ ...formData, regionId: e.value })}
                        /> */}
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={loading ? true : false} color="primary" size="md" type="submit">
                          {loading ? "Editing ..." : " Edit Zone/Town"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default ZoneList;
