import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Row } from "reactstrap";
import { fetchProfileData, saveProfileReport } from "../../auth/store/auth.action";
import { useDispatch } from "react-redux";
import { refresh } from "../../../utils/Utils";
import { postData } from "../../../utils/api";
import { set } from "lodash";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../auth/store/auth.slice";

export const InputField = (label, name, ref, onChange, default_value, errors) => {
  return (
    <Col md="4" key={name}>
      <div className="form-group">
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
        <div className="form-control-wrap">
          <input type="number" id={name} className="form-control" name={name} min="0" ref={ref} onChange={onChange} />
          {errors.total_population && <span className="invalid">This field is required</span>}
        </div>
      </div>
    </Col>
  );
};
export const ProfileForm = ({ level, url, setProfileModal }) => {
  // const user = useSelector((state) => state.Auth.user);
  const user = useSelector(selectCurrentUser);
  const [profileData, setProfileData] = useState({
    total_population: 0,
    total_house_holder: 0,
    total_kebele: 0,
    total_health_post: 0,
    total_health_center: 0,
    total_hospital: 0,
    total_private_hc: 0,
    total_private_clinic: 0,
    total_private_hospital: 0,
    total_primary_school: 0,
    total_secondary_school: 0,
    level_id:
      level == 1
        ? user?.region?.id
        : level == 2
        ? user?.zone?.id
        : level == 3
        ? user?.woreda?.id
        : level == 4
        ? user?.healthFacility?.id
        : user?.community?.id,
    createdAt: new Date(Date.now()).getFullYear(),
  });
  const [errorsArray, setErrorsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onInputChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };
  let currentYear = new Date(Date.now()).getFullYear();

  const submitForm = async (data) => {
    setIsLoading(true);
    postData("/profile-data", { ...profileData, level })
      .then((res) => {
        console.log(res);
        if (res.status === 422) {
          return setErrorsArray(res.data.errors);
        }
        setTimeout(() => {
          dispatch(fetchProfileData(currentYear));
        }, 0);
        setIsLoading(false);
        setProfileModal({ add: false, edit: false });
      })
      .catch((err) => {
        setIsLoading(false);
        // setProfileData({ add: false, edit: false });
      });

    // dispatch(saveProfileReport({ ...profileData, level }));
    // setTimeout(() => {
    //   dispatch(fetchProfileData(currentYear));
    // }, 1000);
    // refresh(url);
  };
  const { errors, handleSubmit, register } = useForm();
  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      {errorsArray.length > 0 && (
        <div className="alert alert-danger">
          <ul>
            {errorsArray.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </ul>
        </div>
      )}
      <Row className="gy-4">
        {InputField(
          "Total Population",
          "total_population",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_population,
          errors
        )}
        {InputField(
          "Total House Hold",
          "total_house_holder",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_house_holder,
          errors
        )}
        {InputField(
          "Total Kebele",
          "total_kebele",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_kebele,
          errors
        )}
        {InputField(
          "Total Health Post",
          "total_health_post",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_health_post,
          errors
        )}
        {InputField(
          "Total Health Center",
          "total_health_center",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_health_center,
          errors
        )}
        {InputField(
          "Total Hospitals",
          "total_hospital",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_hospital,
          errors
        )}
        {InputField(
          "Total Private Health Center",
          "total_private_hc",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_private_hc,
          errors
        )}
        {InputField(
          "Total Private Clinic",
          "total_private_clinic",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_private_clinic,
          errors
        )}
        {InputField(
          "Total Private Hospital",
          "total_private_hospital",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_private_hospital,
          errors
        )}
        {InputField(
          "Total Primary School",
          "total_primary_school",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_primary_school,
          errors
        )}
        {InputField(
          "Total Secondary School",
          "total_secondary_school",
          register({ required: true }),
          (e) => onInputChange(e),
          profileData.total_secondary_school,
          errors
        )}
      </Row>
      <Button disabled={isLoading} className={"mt-5"} color="primary" type="submit">
        {isLoading && <span className="spinner-border  spinner-border-sm me-3" role="status"></span>}
        Save
      </Button>
    </form>
  );
};
