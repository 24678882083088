import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Icon } from "../../../components/Component";
import { deleteData } from "../../../utils/api";
import Swal from "sweetalert2";
import { fetchZoneList } from "../../auth/store/auth.action";

const ActionOptions = ({ url, item, setModal, setSelectedData, reloadData, setLoading, dispatch }) => {
  // console.log(item);

  const deleteHandler = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to get ${item.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        deleteData(`/${url}/${item.id}`)
          .then((res) => {
            Swal.fire("Deleted!", `Zone ${item.name} has been deleted`, "success");
            reloadData();
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
        <Icon name="more-h"></Icon>
      </DropdownToggle>
      <DropdownMenu end>
        <ul className="link-list-plain">
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                setModal({ edit: true, add: false });
                setSelectedData(item);
              }}
            >
              Edit
            </DropdownItem>
          </li>
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              className="text-danger"
              onClick={(ev) => {
                ev.preventDefault();
                deleteHandler();
              }}
            >
              Remove
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ActionOptions;
