import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Modal, ModalBody, Form } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
} from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { selectHFList, selectWoredaList } from "../../auth/store/auth.slice";

import { fetchHFList, fetchWoredaList, saveHF } from "../../auth/store/auth.action";
import { postData, updateData } from "../../../utils/api";
import { PropagateLoader } from "react-spinners";
import ActionOptions from "./ActionOptions";

const HFList = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { woredas } = useSelector(selectWoredaList);
  // const woredaData = useSelector(÷selectWoredaList);
  const { healthFacilities, isLoading, isError } = useSelector(selectHFList);
  // const hFData = useSelector(selectHFList);

  const [sm, updateSm] = useState(false);
  const [onSearchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [formData, setFormData] = useState({
    name: "",

    woredaId: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [errorArray, setErrorArray] = useState([]);
  // const[progress, setProgress] = useState(true)
  // const [itemPerPage, setItemPerPage] = useState(10);

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",

      woredaId: "",
    });
  };

  const reloadData = () => {
    setTimeout(() => {
      dispatch(fetchHFList({ currentPage, all: 0 }));
    }, 0);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = async () => {
    // dispatch(saveHF(formData));
    // console.log(formData, "hf create");
    setLoading(true);
    const result = await postData(`/health-facility`, formData);
    // const result = await dispatch(saveZone(formData));
    console.log(result, "dispatch response");
    if (result?.data?.status == false) {
      setErrorArray(result?.data?.errors);
      setLoading(false);
      return;
    }
    resetForm();
    setLoading(false);
    reloadData();
    setModal({ edit: false }, { add: false });
  };

  const onEditFormSubmit = (submittedData) => {
    console.log(submittedData.woredaId, "before");

    if (!submittedData?.woredaId) {
      submittedData.woredaId = selectedData.woredaId;
    }
    setLoading(true);
    updateData(`/health-facility/${selectedData.id}`, submittedData)
      .then((data) => {
        console.log(data, "response from update");
        reloadData();
        setLoading(false);
        setModal({ edit: false }, { add: false });
        // console.log(submittedData);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, register, setValue, handleSubmit } = useForm();

  useEffect(() => {
    dispatch(fetchWoredaList({ all: 1 }));
    dispatch(fetchHFList({ currentPage, all: 0 }));
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Health Facility Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {healthFacilities?.totalItems} Health Facility.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {/* Loading Component */}
          <>
            {isLoading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="p-2"></span>
                <span>
                  <PropagateLoader className="text-center" color="#3693d6" />
                </span>
              </div>
            )}
            {isError && <span className="text-danger">Something went wrong, try again</span>}
          </>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">S.N.</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Name</span>
                </DataTableRow>

                <DataTableRow size="md">
                  <span className="sub-text">Woreda</span>
                </DataTableRow>

                <DataTableRow size="md">
                  <span className="sub-text">Actions</span>
                </DataTableRow>
              </DataTableHead>

              {/*Head*/}
              {healthFacilities?.totalItems > 0
                ? healthFacilities?.healthFacilityList?.map((item, index) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{index + 1}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-card">
                            <UserAvatar
                              theme={item.avatarBg}
                              text={findUpper(item.name)}
                              image={item.image}
                            ></UserAvatar>
                            <div className="user-info">
                              <span className="tb-lead">
                                {item.name}{" "}
                                <span
                                  className={`dot dot-${
                                    item.status === "Active"
                                      ? "success"
                                      : item.status === "Pending"
                                      ? "warning"
                                      : "danger"
                                  } d-md-none ms-1`}
                                ></span>
                              </span>
                            </div>
                          </div>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{item.woreda?.name}</span>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <ActionOptions
                            setModal={setModal}
                            url={"health-facility"}
                            item={item}
                            reloadData={reloadData}
                            setSelectedData={setSelectedData}
                            setLoading={setLoading}
                          />
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {healthFacilities?.totalItems > 0 ? (
                <PaginationComponent
                  itemPerPage={10}
                  totalItems={healthFacilities?.totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                !isLoading && (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
        </Block>
        {/* add modal */}
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add Health Facility</h5>
              {errorArray.length > 0 && (
                <div className="alert alert-danger">
                  <ul>
                    {errorArray.map((error, index) => {
                      return <li key={index}>{error}</li>;
                    })}
                  </ul>
                </div>
              )}
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">woreda</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={woredas?.woredaList?.map((z) => ({ value: z.id, label: z.name }))}
                          ref={register({ name: "woredaId" })}
                          name="woredaId"
                          onChange={(e) => setFormData({ ...formData, woredaId: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={loading ? true : false} color="primary" size="md" type="submit">
                          {loading ? "Editing ..." : "Add Health Facility"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* edit modal */}
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Edit Health Facility</h5>
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit(onEditFormSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        // onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        defaultValue={selectedData?.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">woreda</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={woredas?.woredaList?.map((z) => ({ value: z.id, label: z.name }))}
                          ref={register({ name: "woredaId" })}
                          name="woredaId"
                          defaultValue={{ value: selectedData?.woredaId, label: selectedData?.woreda?.name }}
                          onChange={(value) => setValue("woredaId", value?.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={loading ? true : false} color="primary" size="md" type="submit">
                          {loading ? "Editing ..." : "Edit Health Facility"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default HFList;
