const menu = [
  {
    icon: "dashboard",
    text: "Dashboard",
    link: "/",
  },
 

  {
    icon: "reports",
    text: "Reports",
    active: false,
    subMenu: [
      {
        text: "Region Report",
        link: "/region-report",
        level: [1],
        icon: "list",
      },
       {
        text: "Zone/Town Report",
        link: "/zone-report",
        level: [1,2],
        icon: "list",
      },
       {
        text: "Woreda Report",
        link: "/woreda-report",
        level: [1,2,3],
        icon: "list",
      }, {
        text: "HF/Hospital Report",
        link: "/hf-report",
        level: [1,2,3,4],
        icon: "list",
      },
       {
        text: "Community Report",
        link: "/community-report",
        level: [1,2,3,4,5],
        icon: "list",
      },
    ],
  },
  {
    icon: "setting",
    text: "Settings",
    active: false,
    isAdminOnly:true ,
    subMenu: [
      {
        text: "Zone/Town",
        link: "/zone-list",
        icon: "list",
      },
       {
        text: "Woreda",
        link: "/woreda-list",
        icon: "list",
      },
      {
        text: "Health Facility",
        icon: "home-alt",
        link: "/hf-list",
      },
      {
        text: "Community",
        link: "/community-list",
        icon: "home",
      },
     
    ],
  },
    {
    icon: "users",
    text: "User Management",
    active: false,
    isAdminOnly:true ,
    subMenu: [
      {
        text: "User List",
        link: "/user-list-regular",
        icon: "user",
      },
     
    ],
  },
 
 
];
export default menu;
