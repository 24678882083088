import React, { useEffect, useState } from "react";
import { LineChartExample, PreviewCard } from "../../components/Component";
import { barChartData, solidLineChart } from "../components/charts/ChartData";
import { getData } from "../../utils/api";

const ChartTwo = ({ currentYear, title }) => {
  const [chart, setChart] = useState(solidLineChart);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let getDashboardData = async () => {
    try {
      setIsLoading(true);
      let result = await getData(`/dashboard-sbc/${currentYear}`);
      let data = result?.data;

      setChart((prev) => {
        return {
          ...prev,
          labels: data?.labels,

          datasets: [
            {
              label: "Zones",
              borderColor: "#5ce0aa",
              backgroundColor: "white",
              pointBorderWidth: 2,
              fill: false,
              categoryPercentage: 0.9,
              barPercentage: 0.6,
              data: data?.zoneData,
            },
            {
              label: "Woreda",
              backgroundColor: "white",
              pointBorderWidth: 2,
              borderColor: "#798bff",
              fill: false,
              categoryPercentage: 0.9,
              barPercentage: 0.6,
              data: data?.woredaData,
            },
          ],
        };
      });
      setIsLoading(false);
      //   console.log(result);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [currentYear]);

  return (
    <PreviewCard>
      <div className="card-head">
        <h6 className="title">{title}</h6>
      </div>
      {isLoading ? (
        <div className="nk-ck-sm d-flex align-items-center justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="nk-ck">
          <LineChartExample legend={true} data={chart} />
        </div>
      )}
    </PreviewCard>
  );
};

export default ChartTwo;
