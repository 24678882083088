import React from "react";
import Select from "react-select";

// Year Selector Component
const YearSelector = ({ onChange, selectedYear }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2023 }, (_, index) => currentYear - index).map((year) => ({
    value: year,
    label: year,
  }));
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 100, // Set your desired width here
    }),
  };
  return (
    <div className="w-full ">
      <span>Year _ ({selectedYear})</span>
      <Select
        options={years}
        value={{ value: selectedYear, label: selectedYear }}
        onChange={(selectedOption) => onChange(selectedOption.value)}
        styles={customStyles}
      />
    </div>
  );
};

export default YearSelector;
