import React, { useState } from "react";

import { Button, Col, RSelect } from "../../../components/Component";

import { Row } from "reactstrap";
import { monthList, ageCategory } from "./ProjectData";
import { refresh } from "../../../utils/Utils";
import { useForm } from "react-hook-form";

import { useDispatch } from "react-redux";
import {
  fetchReportSessions,
  saveReport,
  updateReportLocalList,
  updateReportSessionLocal,
} from "../../auth/store/auth.action";
import { useSelector } from "react-redux";
import { selectCurrentUser, selectLocalReportList, selectLocalReportSession } from "../../auth/store/auth.slice";

export const IndicatorForm = (props) => {
  const [level] = useState(props.level);
  // console.log(props.test);
  // console.log(props.setModal);
  const [formData, setFormData] = useState({});

  const dispatch = useDispatch();
  const localReportList = useSelector(selectLocalReportList);
  const localReportSession = useSelector(selectLocalReportSession);
  const user = useSelector(selectCurrentUser);
  // console.log(user);
  const indicator = props.indicator;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSelectChange = (e) => {
    dispatch(
      updateReportSessionLocal({
        month: e.value,
        level: level,
        report_level:
          level == 1
            ? user?.region
            : level == 2
            ? user?.zone
            : level == 3
            ? user?.woreda
            : level == 4
            ? user?.healthFacility
            : user?.community,
        level_id:
          level == 1
            ? user?.region?.id
            : level == 2
            ? user?.zone?.id
            : level == 3
            ? user?.woreda?.id
            : level == 4
            ? user?.healthFacility?.id
            : user?.community?.id,
        userId: user?.id,
        reported_at: Date.now(),
      })
    );
  };

  const { errors, handleSubmit, register } = useForm();

  const submitForm = (data) => {
    // console.log(data, "formData");
    if (Object.keys(data).length > 0) {
      const d = {
        ...data,
        report_session: 3,
        regionId: Number(user?.regionId) || null,
        zoneId: Number(user?.zoneId) || null,
        woredaId: Number(user?.woredaId) || null,
        healthFacilityId: Number(user?.healthFacilityId) || null,
        communityId: Number(user?.communityId),
      };
      dispatch(updateReportLocalList(d));
    }

    if (!props.hasNext()) {
      // if(props.current === 3){
      dispatch(saveReport({ session: localReportSession, report: localReportList }));

      console.log(localReportSession, "local session");
      console.log(localReportList, "local report data");

      // refresh(props.url);
      setTimeout(() => {
        dispatch(fetchReportSessions({ id: level, currentPage: 1 }));
      }, 1000);

      props?.setModal({ edit: false, add: false });
    } else {
      props.next();
    }
  };
  const handlePrev = (data) => {
    props.prev();
  };

  // console.log(user, "from indicator form");

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      {props.index === -1 && (
        <Row className="gy-4">
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Month List</label>
              <RSelect
                options={monthList}
                id="month"
                required={true}
                placeholder="Select"
                name="month"
                ref={register({ required: true })}
                defaultValue={formData?.month}
                // onChange={(e) => onInputChange(e)}
                onChange={onSelectChange}
              />
            </div>
          </Col>
        </Row>
      )}
      {props.index !== -1 && (
        <Row className="gy-4">
          <Col md="12">
            {" "}
            {indicator.name}{" "}
            <input
              type="number"
              id="parent"
              className="form-control"
              name="parentId"
              min="0"
              {...register(`parent`)}
              value={indicator.id}
              hidden
              ref={register({ required: true })}
              onChange={(e) => onInputChange(e)}
            />
          </Col>

          {indicator.type_label && (
            <Col md="9">
              <div className="form-group">
                <label className="form-label" htmlFor="title">
                  {indicator.type_label}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="title"
                    className="form-control"
                    name="title"
                    min="0"
                    ref={register({ required: true })}
                    onChange={(e) => onInputChange(e)}
                    defaultValue={formData.title}
                  />
                  {errors.title && <span className="invalid">This field is required</span>}
                </div>
              </div>
            </Col>
          )}

          {indicator.has_session && (
            <Col md="4">
              <div className="form-group">
                <label className="form-label" htmlFor="session">
                  Number of Sessions
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    id="session"
                    className="form-control"
                    name="session"
                    min="0"
                    ref={register({ required: true })}
                    onChange={(e) => onInputChange(e)}
                    defaultValue={formData.session}
                  />
                  {errors.session && <span className="invalid">This field is required</span>}
                </div>
              </div>
            </Col>
          )}
          {indicator.has_gender && (
            <Col md="4">
              <div className="form-group">
                <label className="form-label" htmlFor="male_count">
                  Male Participants
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    id="male_count"
                    className="form-control"
                    name="male_count"
                    min="0"
                    ref={register({ required: true })}
                    onChange={(e) => onInputChange(e)}
                    defaultValue={formData.male_count}
                  />
                  {errors.male_count && <span className="invalid">This field is required</span>}
                </div>
              </div>
            </Col>
          )}
          {indicator.has_gender && (
            <Col md="4">
              <div className="form-group">
                <label className="form-label" htmlFor="female_count">
                  Female Participants
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    id="female_count"
                    className="form-control"
                    name="female_count"
                    min="0"
                    ref={register({ required: true })}
                    onChange={(e) => onInputChange(e)}
                    defaultValue={formData.female_count}
                  />
                  {errors.female_count && <span className="invalid">This field is required</span>}
                </div>
              </div>
            </Col>
          )}
          <Col md="4">
            <div className="form-group">
              <label className="form-label" htmlFor="total">
                {indicator.total_label}
              </label>
              <div className="form-control-wrap">
                <input
                  type="number"
                  id="total"
                  className="form-control"
                  name="total"
                  readOnly={formData.male_count || formData.female_count}
                  min="0"
                  value={
                    formData.male_count || formData.female_count
                      ? Number(formData.male_count) + Number(formData.female_count)
                      : formData.total
                  }
                  ref={register({ required: true })}
                  onChange={(e) => onInputChange(e)}
                  defaultValue={formData.total}
                />
                {errors.total && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </Col>

          {indicator.has_age_category && (
            <Col md="4">
              <div className="form-group">
                <label className="form-label" htmlFor="age_category">
                  Age Category
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="age_category"
                    className="form-control"
                    name="age_category"
                    min="0"
                    ref={register({ required: true })}
                    onChange={(e) => onInputChange(e)}
                    defaultValue={formData.age_category}
                  />
                  {errors.age_category && <span className="invalid">This field is required</span>}
                </div>
              </div>
            </Col>
          )}
          {indicator.has_number_of_distributed && (
            <Col md="4">
              <div className="form-group">
                <label className="form-label" htmlFor="number_of_distributed">
                  Number of Distributed
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    id="number_of_distributed"
                    className="form-control"
                    name="number_of_distributed"
                    min="0"
                    ref={register({ required: true })}
                    onChange={(e) => onInputChange(e)}
                    defaultValue={formData.number_of_distributed}
                  />
                  {errors.number_of_distributed && <span className="invalid">This field is required</span>}
                </div>
              </div>
            </Col>
          )}
          {indicator.has_estimated_cost && (
            <Col md="4">
              <div className="form-group">
                <label className="form-label" htmlFor="estimated_cost">
                  Estimated Cost(ETB)
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    id="estimated_cost"
                    className="form-control"
                    name="estimated_cost"
                    min="0"
                    ref={register({ required: true })}
                    onChange={(e) => onInputChange(e)}
                    defaultValue={formData.estimated_cost}
                  />
                  {errors.estimated_cost && <span className="invalid">This field is required</span>}
                </div>
              </div>
            </Col>
          )}
          {indicator.has_reach && (
            <Col md="4">
              <div className="form-group">
                <label className="form-label" htmlFor="number_of_reach">
                  Number of Reach
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    id="number_of_reach"
                    className="form-control"
                    name="number_of_reach"
                    min="0"
                    ref={register({ required: true })}
                    onChange={(e) => onInputChange(e)}
                    defaultValue={formData.number_of_reach}
                  />
                  {errors.number_of_reach && <span className="invalid">This field is required</span>}
                </div>
              </div>
            </Col>
          )}
          {indicator.has_negative_positive && (
            <Col md="4">
              <div className="form-group">
                <label className="form-label" htmlFor="positive_comment_count">
                  Number of Positive comment
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    id="positive_comment_count"
                    className="form-control"
                    name="positive_comment_count"
                    min="0"
                    ref={register({ required: true })}
                    onChange={(e) => onInputChange(e)}
                    defaultValue={formData.positive_comment_count}
                  />
                  {errors.positive_comment_count && <span className="invalid">This field is required</span>}
                </div>
              </div>
            </Col>
          )}
          {indicator.has_negative_positive && (
            <Col md="4">
              <div className="form-group">
                <label className="form-label" htmlFor="negative_comment_count">
                  Number of Negative comment
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    id="negative_comment_count"
                    className="form-control"
                    name="negative_comment_count"
                    min="0"
                    ref={register({ required: true })}
                    onChange={(e) => onInputChange(e)}
                    defaultValue={formData.negative_comment_count}
                  />
                  {errors.negative_comment_count && <span className="invalid">This field is required</span>}
                </div>
              </div>
            </Col>
          )}
        </Row>
      )}
      <div className="actions clearfix">
        <ul>
          <li>
            {props.hasPrev() && (
              <Button className={"ml-4 m-4"} color="warning" onClick={handlePrev}>
                Previous
              </Button>
            )}
            <Button color="primary" type="submit">
              {props.hasNext() ? "Next" : "Submit"}
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};
