import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Card, Modal, ModalBody, Badge } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  OverlineTitle,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import { useHistory } from "react-router";
import { currentTime, findUpper, monthNames, refresh, todaysDate } from "../../../utils/Utils";
import { UserContext } from "./UserContext";
import { notes } from "./UserData";
import { exportUser, fetchCurrentUser, fetchUserDetail, resetPassword } from "../../auth/store/auth.action";

import { selectCurrentUser, selectUserDetail } from "../../auth/store/auth.slice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { deleteData, getMe, updateData } from "../../../utils/api";
import { FadeLoader } from "react-spinners";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import moment from "moment";

const UserDetailsPage = ({ match }) => {
  const [sideBar, setSidebar] = useState(false);
  const user = useSelector(selectUserDetail);

  const currentUser = useSelector(selectCurrentUser);
  // console.log(user, "user details ");
  const convertToCSV = (data) => {
    const {
      id,
      deletedAt,
      fileId,
      status,
      region,
      zone,
      woreda,
      health_facility,
      community,
      zoneId,
      woredaId,
      communityId,
      regionId,
      healthFacilityId,
      createdAt,
      updatedAt,
      role,
      photoId,
      ...other
    } = data;
    const worksheet = XLSX.utils.json_to_sheet([other]);
    const csv = XLSX.utils.sheet_to_csv(worksheet, { forceQuotes: true });
    return csv;
  };

  // Function to trigger download
  const downloadCSV = () => {
    const csv = convertToCSV(user);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${user?.firstName}-${user?.lastName}-${user?.id}.csv`);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  // console.log(user, "user details");
  const [passUpdating, setPassUpdating] = useState(false);
  const [error, setError] = useState("");
  const [updating, setUpdating] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });

  // initial state for password reset
  const [formData, setFormData] = useState({
    id: user?.id,
    old_password: "",
    new_password: "",
    repeat_password: "",
  });
  const { errors, handleSubmit, watch, register } = useForm();

  const submitForm = async (data) => {
    let userObj = { ...data, user_id: match.params.id };
    console.log(userObj);
    setPassUpdating(true);
    updateData(`/admin/users/update-password`, userObj)
      .then((res) => {
        if (res.data?.status == false) {
          setError(res.data?.message);
        } else {
          onFormCancel();
        }
        setPassUpdating(false);
      })
      .catch((err) => {
        setPassUpdating(false);
      });

    // // dispatch(resetPassword({ ...data, user_id: user?.id }));
  };

  // user edit handler
  // console.log(user,"formik")
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName || "",
      middleName: user?.middleName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      phoneNumber: user?.phoneNumber || "",
      gender: user?.gender || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string(),
      middleName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string().email("Invalid email address"),
      phoneNumber: Yup.string(),
      gender: Yup.string().required("gender is required"),
    }),
    // update user handler by admin
    onSubmit: async (values) => {
      // console.log(values);
      // console.log(user);
      setUpdating(true);
      // console.log(values);
      try {
        updateData(`/users/${match?.params?.id}`, values)
          .then((res) => {
            setTimeout(() => {
              dispatch(fetchCurrentUser());
              dispatch(fetchUserDetail(match.params.id));
            }, 1000);
            setUpdating(false);
            setModal({ edit: false, add: false });
          })
          .catch((err) => {
            setUpdating(false);
          });
        // console.log(result);
      } catch (error) {
        // console.log(error?.response);
        setUpdating(false);
        setModal({ edit: false, add: false });
      }

      // alert(JSON.stringify(values, null, 2));
    },
  });

  // function to toggle sidebar
  const toggle = () => {
    setSidebar(!sideBar);
  };

  const getLevel = () => {
    if (user.level === 1) return "Region";
    if (user.level === 2) return "Zone/Town";
    if (user.level === 3) return "Woreda";
    if (user.level === 4) return "Health Facility";
    if (user.level === 5) return "Community";
    return "Not Defined";
  };
  const resetForm = () => {
    setFormData({
      id: user?.id,
      old_password: "",
      new_password: "",
      repeat_password: "",
    });
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };
  // delete a note
  const deleteUserAccount = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(`/admin/users/${match.params.id}`)
          .then((res) => {
            history.goBack();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  // grabs the id of the url and loads the corresponding data
  useEffect(() => {
    const id = match.params.id;
    if (id !== undefined || null || "") {
      dispatch(fetchUserDetail(id));
    }
    dispatch(fetchCurrentUser("df"));
  }, [match.params.id]);

  return (
    <React.Fragment>
      <Head title="User Details - Regular"></Head>
      {user ? (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Users / <strong className="text-primary small">{user?.role?.name}</strong>
                </BlockTitle>
                {/* <a href={csv} download>
                  download
                </a> */}
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      User ID: <span className="text-base">{user?.id}</span>
                    </li>
                    {/* <li>
                      Last Login: <span className="text-base">{user.lastLogin}</span>
                    </li> */}
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent className={"d-flex align-items-center gap-3"}>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={() => setModal({ add: true })}
                >
                  <Icon name="download"></Icon>
                  <span>Reset Password</span>
                </Button>
                <Button color="light" outline className="bg-white d-none d-sm-inline-flex" onClick={downloadCSV}>
                  <Icon name="download"></Icon>
                  <span>Export</span>
                </Button>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={() => history.goBack()}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    history.goBack();
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        href="#personal"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="user-circle"></Icon>
                        <span>Personal</span>
                      </a>
                    </li>

                    <li className="nav-item nav-item-trigger d-xxl-none">
                      <Button className={`toggle btn-icon btn-trigger ${sideBar && "active"}`} onClick={toggle}>
                        <Icon name="user-list-fill"></Icon>
                      </Button>
                    </li>
                  </ul>

                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Person Information</BlockTitle>
                        <p>Basic info</p>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">ID</span>
                            <span className="profile-ud-value">{user?.id}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Full Name</span>
                            <span className="profile-ud-value">{`${user?.firstName} ${user?.lastName}`}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Registration Date</span>
                            <span className="profile-ud-value">
                              {moment(user?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                            </span>
                          </div>
                        </div>

                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Mobile Number</span>
                            <span className="profile-ud-value">{user?.phoneNumber}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Email Address</span>
                            <span className="profile-ud-value">{user?.email}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Status</span>
                            <span className="profile-ud-value">{user?.statusId == 1 ? "Active" : "In Active"}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Role</span>
                            <span className="profile-ud-value">
                              {user.roleId == 2 ? "System User" : user.roleId == 1 ? "Admin" : "Default"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Block>

                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Access Area
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Region</span>
                            <span className="profile-ud-value">{user.zone?.region?.name || "NONE"}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Zone/Town</span>
                            <span className="profile-ud-value">
                              {user?.zone?.name
                                ? user?.zone?.name
                                : user?.region?.name
                                ? `Every Zone Under  ${user?.region?.name}`
                                : "_____"}
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Woreda</span>
                            <span className="profile-ud-value">
                              {user?.woreda?.name
                                ? user?.woreda?.name
                                : user?.zone?.name
                                ? `Every Woreda Under ${user?.zone?.name}`
                                : "_____"}
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Health Facility</span>
                            <span className="profile-ud-value">
                              {user?.health_facility?.name
                                ? user?.health_facility?.name
                                : user?.woreda?.name
                                ? `Every Health Facility Under ${user?.woreda?.name}`
                                : "_____"}
                            </span>
                          </div>
                        </div>
                        {/* <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Community</span>
                            <span className="profile-ud-value">
                              {user?.community?.name
                                ? user?.community?.name
                                : user?.healthFacility?.name
                                ? `Every Community Under ${user?.healthFacility?.name}`
                                : "NONE"}

                           
                            </span>
                          </div>
                        </div> */}

                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Access Level</span>
                            <span className="profile-ud-value">{getLevel()}</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Joining Date</span>
                            <span className="profile-ud-value">
                              {moment(user.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Last Edit</span>
                            <span className="profile-ud-value">
                              {moment(user?.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Reg Method</span>
                            <span className="profile-ud-value">By Admin</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Country</span>
                            <span className="profile-ud-value">{"Ethiopia"}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Nationality</span>
                            <span className="profile-ud-value">{"Ethiopian"}</span>
                          </div>
                        </div>
                      </div>
                    </Block>

                    <div className="nk-divider divider md"></div>
                    <div className="d-flex justify-content-end">
                      {currentUser?.roleId == 1 && (
                        <span
                          onClick={deleteUserAccount}
                          style={{ cursor: "pointer" }}
                          className="btn btn-danger btn-sm"
                        >
                          Remove User Account
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* right side side bar */}
                <Sidebar toggleState={sideBar}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2 mt-5 mt-xxl-0">
                      <UserAvatar className="lg" theme="primary" text={findUpper(user.firstName)} />
                      <div className="user-info">
                        <Badge color="outline-light" pill className="ucap">
                          {user.firstName} {user?.lastName}
                        </Badge>
                        <h5>{/* {user.name} */}</h5>
                        <span className="sub-text">{user.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner card-inner-sm">
                    <ul className="btn-toolbar justify-center gx-1">
                      {/* <li>
                        <Button
                          href="#tool"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="shield-off"></Icon>
                        </Button>
                      </li> */}
                      {/* <li>
                        <Button
                          href="#mail"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="mail"></Icon>
                        </Button>
                      </li> */}
                      {/* edit */}
                      <li
                        onClick={(ev) => {
                          ev.preventDefault();
                          setModal({ edit: true });
                        }}
                      >
                        <Button href="#download" className="btn-trigger btn-icon">
                          <Icon name="edit"></Icon>
                        </Button>
                        <span className="ms-1">Edit</span>
                      </li>
                      {/* <li>
                        <Button
                          href="#bookmark"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="bookmark"></Icon>
                        </Button>
                      </li> */}
                      {/* <li>
                        <Button
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon text-danger"
                        >
                          <Icon name="na"></Icon>
                        </Button>
                      </li> */}
                    </ul>
                  </div>
                </Sidebar>
                {sideBar && <div className="toggle-overlay" onClick={() => toggle()}></div>}
              </div>
            </Card>
          </Block>
          {/* password change modal */}
          <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="md">
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="p-2">
                <h5 className="title">Reset Password</h5>
                <div className="mt-4">
                  <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                    {error && <span className="text-danger">{error}</span>}
                    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
                      <Row className="gy-4">
                        <Col md="12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="title">
                              Previous Password
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="user_id"
                                hidden
                                {...register(`user_id`)}
                                value={user?.id}
                              />
                              <input
                                type="password"
                                className="form-control"
                                name="old_password"
                                {...register(`old_password`, { required: "please provide your old password" })}
                                ref={register({ required: true })}
                                onChange={(e) => onInputChange(e)}
                              />
                              {errors.old_password && <span className="invalid">{errors.old_password.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="title">
                              New Password
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="password"
                                className="form-control"
                                name="new_password"
                                min="0"
                                {...register(`new_password`, { required: "please provide your new password" })}
                                ref={register({ required: true, minLength: 4 })}
                                onChange={(e) => onInputChange(e)}
                              />
                              {errors.new_password && <span className="invalid">{errors.new_password.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="title">
                              Confirm Password
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="password"
                                className="form-control"
                                name="repeat_password"
                                min="0"
                                {...register(`repeat_password`, { required: "please confirm your password" })}
                                ref={register({
                                  required: true,
                                  validate: (val) => {
                                    if (watch("new_password") != val) {
                                      return "Your passwords do no match";
                                    }
                                  },
                                })}
                                onChange={(e) => onInputChange(e)}
                              />
                              {errors.repeat_password && (
                                <span className="invalid">{errors?.repeat_password.message}</span>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="actions clearfix mt-4">
                        <Button disabled={passUpdating} color="primary" className={"float-right"} type="submit">
                          {passUpdating ? " Reseating ..." : "Reset Password"}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>

          {/* user update modal */}
          <Modal
            isOpen={modal.edit}
            toggle={() => setModal({ edit: false })}
            className="modal-dialog-centered"
            size="md"
          >
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  setModal({ edit: false, add: false });
                  formik.resetForm();
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="p-2">
                <h5 className="title">My Profile</h5>
                <div className="mt-4">
                  <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                    <form className="content clearfix" onSubmit={formik.handleSubmit}>
                      <Row className="gy-4">
                        {/* alst name */}
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="title">
                              First Name
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        </Col>
                        {/* middle name */}
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="title">
                              Middle Name
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="middleName"
                                value={formik.values.middleName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        </Col>
                        {/* last name */}
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="title">
                              Last Name
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        </Col>
                        {/* gender */}
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="title">
                              Gender
                            </label>
                            <div className="form-control-wrap">
                              <select
                                className="form-control"
                                type="text"
                                autoComplete="on"
                                name="gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.gender}
                              >
                                <option value={""}> -- Select Gender -- </option>
                                <option value={"Male"}>Male</option>
                                <option value={"Female"}>Female</option>
                              </select>
                              {formik.touched.gender && formik.errors.gender ? (
                                <small className="text-danger">{formik.errors.gender}</small>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                        {/* email */}
                        <Col md="12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="title">
                              Email Address
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <small className="text-danger">{formik.errors.email}</small>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                        {/* phone number */}
                        <Col md="12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="title">
                              Phone Number
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="phoneNumber"
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="actions clearfix mt-4">
                        <Button color="primary" className={"float-right"} type="submit">
                          {updating ? "Updating ..." : "Update My Profile"}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </Content>
      ) : (
        <FadeLoader color="#36d7b7" />
      )}
    </React.Fragment>
  );
};
export default UserDetailsPage;
