import { getData, getFile, getMe, postData, updateData } from "../../utils/api";

class AppController {
  getUserList = (obj) => getData(`/admin/users?page=${obj.currentPage}&&q=${obj.q}`); // user list by admin
  getUserDetail = (id) => getData(`/users/${id}`); // user detail profile
  getMe = () => getMe(); // get me
  getZoneList = (data) => getData(`/zones?page=${data.currentPage}&&all=${data.all}`);
  getWoredaList = (data) => getData(`/woreda?page=${data.currentPage}&&all=${data.all}`);

  getHFList = (data) => getData(`/health-facility?page=${data.currentPage}&&all=${data.all}`);
  getCommunityList = (data) => getData(`/community?page=${data.currentPage}&&all=${data.all}`);

  getIndicatorsList = (level) => getData(`/indicators/${level}`); // indicator lists for every levels
  fetchReport = (id) => getData(`/reports/${id}`); //fetch single reports
  fetchDashboard = () => getData(`/dashboard`);
  fetchReportSessionList = (data) => getData(`/reportSessions/${data?.id}?page=${data?.currentPage}&pageSize=10`); // fetch all report with pagination
  //
  updateUser = (data) => updateData("/users"); // update user
  //save user (register)
  saveUser = (data) => postData(`/admin/signup`, data);

  saveProfileData = (data) => postData(`/profile-data`, data);

  // get current profile data
  getProfileData = (year) => getData(`/profile-data-by-year/${year}`);

  saveReports = (data) => postData(`/reports`, data);
  resetPassword = (data) => updateData(`/users/update-password`, data);
  saveWoreda = (data) => postData(`/woredas`, data);
  saveHF = (data) => postData(`/health-facility`, data);
  saveCommunity = (data) => postData(`/community`, data);

  // zone saving
  saveZone = (data) => postData(`/zones`, data);
  saveReportSession = (data) => postData(`/reportSessions/`, data);
  exportReport = (id) => getFile(`/export/${id}`);
  login = (data) => postData(`/login`, data);
}
export const appController = new AppController();
